class About {
  constructor() {
    this.init();
  }

  init() {
    var titles = jQuery(".about-approach-item");

    var addClick = function () {
      if (window.innerWidth < 1025) {
        titles.on("click", function () {
          jQuery(this).toggleClass("about-approach-item--active");
        });
      }
    };

    addClick();

    jQuery(window).resize(addClick);
  }
}

export { About };
